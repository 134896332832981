import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { apiHooks } from '@/hooks/apis';
import NumberCard from './number-card';

const NumberCards = forwardRef<HTMLDivElement>((props, ref) => {
	const { t } = useTranslation();

	const { data: account } = apiHooks.useGetAccount();

	const cardsInfo = [
		{
			title: t('Available Balance'),
			number: account?.balance || 0,
			tips: t(
				'Available Balance = Total Prepaid Amount + Credit - Order Amount - Total Settled Amount',
			),
		},
		{
			title: t('Total Prepaid Amount'),
			number: account?.prepaid_amount || 0,
			tips: t(
				'The total amount pre-deposited into the account, available for order payments or other fees.',
			),
		},
		{
			title: t('Credit'),
			number: account?.credit_amount || 0,
			tips: t(
				'The credit limit granted by the platform, which can be used to facilitate payments.',
			),
		},
		{
			title: t('Order Amount'),
			number: account?.order_amount || 0,
			tips: t(
				'The total amount of placed orders that are still pending settlement, representing unfinalized payments.',
			),
		},
		{
			title: t('Total Settled Amount'),
			number: account?.settled_amount || 0,
			tips: t('The total amount of orders that have been successfully settled and paid.'),
		},
	];
	return (
		<div className='flex flex-wrap w-full gap-4' ref={ref}>
			{cardsInfo.map((info) => (
				<NumberCard key={info.title} {...info} />
			))}
		</div>
	);
});

export default NumberCards;
