import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import AddFundsDrawer from './add-funds-drawer';

const AddFundsButton = ({ refreshPrepayOrders }: { refreshPrepayOrders: () => void }) => {
	const { t } = useTranslation();

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const handleOpenDrawer = () => setIsDrawerOpen(true);
	const handleCloseDrawer = () => setIsDrawerOpen(false);

	return (
		<>
			<Button type='primary' onClick={handleOpenDrawer}>
				{t('Add Funds')}
			</Button>
			<AddFundsDrawer
				open={isDrawerOpen}
				onClose={handleCloseDrawer}
				refreshPrepayOrders={refreshPrepayOrders}
			/>
		</>
	);
};

export default AddFundsButton;
