import { useEffect, useState } from 'react';
import { message } from 'antd';
import { userContext } from '@/services/contexts/user';

const Balance = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const user = userContext.getUserInfo();

	const refreshBalance = async () => {
		setLoading(true);
		try {
			await userContext.refreshBalance();
		} catch (e) {
			message.error(e.message);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		void refreshBalance();
	}, []);

	return <>{children(user?.balance || '-', loading)}</>;
};

export default Balance;
