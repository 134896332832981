import { useTranslation } from 'react-i18next';
import BlueCircle18pxIcon from '@/icons/blue-circle-18px-icon';

const ListItem = ({ title, content }: { title: string; content: string }) => (
	<div className='grid grid-cols-[26px_1fr] grid-rows-[26px_1fr]'>
		<BlueCircle18pxIcon />
		<div>{title}</div>
		<div></div>
		<div>{content}</div>
	</div>
);

export const Attentions = () => {
	const { t } = useTranslation();

	return (
		<div className='flex flex-col items-start gap-[16px]'>
			<ListItem
				title={t(
					'Please inform the warehouse to print the BOL in advance and hand it over to the driver',
				)}
				content={t(
					'Please inform the pickup warehouse to have the Bill of Lading (BOL) printed in advance and hand it to the driver during pickup. Otherwise, the driver will not proceed with the pickup, and a dry run fee may be incurred. If you need the trucking company to bring a pre-printed BOL for the pickup, please consult your dedicated account manager.',
				)}
			/>
			<ListItem
				title={t('When the goods are abnormal, it needs to be noted on the POD')}
				content={t(
					`Please remind the recipient: If there are any issues with the goods, such as damage or missing items, the recipient must note the specific details on the carrier's POD (Proof of Delivery) and keep a copy. Otherwise, no further claims can be processed. If there is only one POD, the recipient should make a note of the issue and take a photo for record-keeping.`,
				)}
			/>
			<ListItem
				title={t('Explanation of the POD aging period')}
				content={t(
					'After the delivery is completed, the POD (Proof of Delivery) will be returned by the carrier, which is expected to take 1-2 business days. We sincerely apologize for any inconvenience this may cause and kindly ask for your patience.',
				)}
			/>
			<ListItem
				title={t('Customer palletizing and packaging instructions')}
				content={t(
					"Please ensure that the outer packaging of the goods is sturdy, arranged neatly on the pallet, and tightly wrapped with film. Any damage occurring during transit due to issues with the product's packaging will not be compensated. Drivers may also refuse to pick up the goods due to the high risk of damage, which could result in delays and dry drun fees.",
				)}
			/>
		</div>
	);
};
