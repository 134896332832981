import { useTranslation } from 'react-i18next';
import useAddressesParams, { AddressesQuery } from './use-addresses-params';
import { apiHooks } from '@/hooks/apis';
import { Warehouse } from '@/interfaces/warehouse.interface';
import {
	Button,
	Dropdown,
	DropdownProps,
	Input,
	message,
	Pagination,
	Table,
	TableProps,
} from 'antd';
import { DeleteOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';
import { useMount, useSetState } from 'ahooks';
import '@/styles/no-cell-border-table.less';
import { useMemoizedFn, useDebounce } from 'ahooks';
import { Gap } from '@/components/gap';
import Search16pxIcon from '@/icons/search-16px-icon';
import useAddressModal from '../search-rate/book/components/route-info/components/use-address-modal';
import { deleteWarehouse } from '@/services/apis';

const tableScroll: TableProps<Warehouse>['scroll'] = {
	scrollToFirstRowOnChange: true,
	x: 'max-content',
	y: 'calc(100vh - 345px)',
};

const Addresses = () => {
	const { t } = useTranslation();

	const { AddressModal, openAddressModal } = useAddressModal();

	const { parsedSearchParams, changeSearchParams } = useAddressesParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Pick<AddressesQuery, 'keyword'>
	>({
		keyword: parsedSearchParams.keyword ?? undefined,
	});

	const debouncedQueriesWithoutPagination = useDebounce(queriesWithoutPagination, { wait: 500 });

	const {
		runAsync: getWarehouses,
		data: warehouses,
		loading: warehousesLoading,
		pagination,
		refresh,
	} = apiHooks.useGetWarehouses({
		refreshDeps: [debouncedQueriesWithoutPagination],
		refreshDepsAction: () => {
			void getWarehouses(
				{
					current: 1,
					pageSize,
				},
				{
					params: {
						...debouncedQueriesWithoutPagination,
					},
				},
			);
		},
	});

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const dropdownMenuItems: DropdownProps['menu']['items'] = [
		{
			key: 'edit',
			label: t('Edit Address'),
			icon: <EditOutlined className='text-[24px] text-primary-regular' />,
		},
		{
			key: 'delete',
			label: t('Delete'),
			icon: <DeleteOutlined className='text-[24px] text-updated-red' />,
		},
	];
	const handleDropdownMenuClick: (warehouse: Warehouse) => DropdownProps['menu']['onClick'] =
		(warehouse) =>
		({ key, domEvent }) => {
			domEvent.stopPropagation();
			domEvent.preventDefault();
			if (key === 'edit') {
				return openAddressModal(warehouse);
			}
			if (key === 'delete') {
				return deleteWarehouse({ path: { warehouseId: warehouse.id } })
					.then(() => {
						message.success(t('Delete address success'));
					})
					.catch(() => {
						message.error(t('Delete address failed'));
					})
					.finally(() => {
						refresh();
					});
			}
			return;
		};

	const columns: TableProps<Warehouse>['columns'] = [
		{
			title: t('Name'),
			dataIndex: 'name',
			width: '130px',
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: t('Code'),
			dataIndex: 'code',
			width: '80px',
			// a.code may be null, fix below sorter
			sorter: (a, b) => (a.code || '').localeCompare(b.code || ''),
		},
		{
			title: t('City'),
			dataIndex: ['city', 'full_name'],
			width: '100px',
			sorter: (a, b) => (a.city?.full_name || '').localeCompare(b.city.full_name),
		},
		{
			title: t('Address'),
			dataIndex: 'address',
			width: '200px',
		},
		{
			title: t('D/O Address'),
			width: '200px',
		},
		{
			title: `${t('Contact')}/${t('Phone')}/${t('Email')}`,
			dataIndex: 'contacts',
			width: '240px',
			render: (contacts: Warehouse['contacts']) => {
				const contact = contacts?.[0] ?? { name: '', phone: '', email: '' };
				return (
					<div>
						<div>{contact.name}</div>
						<div>{contact.phone}</div>
						<div>{contact.email}</div>
					</div>
				);
			},
			sorter: (a, b) => a.contacts?.[0].name.localeCompare(b.contacts?.[0].name),
		},
		{
			title: t('Creator'),
			dataIndex: ['user', 'name'],
			width: '80px',
			sorter: (a, b) => a.user.name.localeCompare(b.user.name),
		},
		{
			title: ' ',
			width: '36px',
			render: (_, warehouse) => (
				<Dropdown menu={{ items: dropdownMenuItems, onClick: handleDropdownMenuClick(warehouse) }}>
					<Button type='text' icon={<MoreOutlined />} />
				</Dropdown>
			),
		},
	];

	const handleChange = useMemoizedFn((newQueries: AddressesQuery) => {
		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) => ['sort_by', 'sort_value', 'keyword'].includes(k))
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		handleChange({
			page,
			per_page: pageSize,
		});
	});

	useMount(() => {
		void (async () => {
			await getWarehouses(
				{
					current: parsedSearchParams.page ?? 1,
					pageSize: parsedSearchParams.per_page ?? 20,
				},
				{
					params: {
						...debouncedQueriesWithoutPagination,
					},
				},
			);
			changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
		})();
	});

	return (
		<div className='relative h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t('Address')}</div>
				{warehousesLoading && <LoadingOutlined />}
				<div className='flex-auto'></div>
				<Button type='primary' onClick={() => openAddressModal()}>
					{t('New Address')}
				</Button>
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px] flex items-center gap-[12px] flex-wrap'>
				<Input
					value={queriesWithoutPagination.keyword}
					prefix={<Search16pxIcon />}
					placeholder={t('Search for ID, Name, Code, City, Address, D/O Address, Phone/Email....')}
					className='h-[42px] w-full border-[1px] border-solid border-grey-04'
					onChange={(e) => handleChange({ keyword: e.target.value })}
				/>
			</div>

			<Gap height='24px' />

			{/* table */}
			<div className='flex-auto'>
				<Table
					className='no-cell-border-table'
					rowKey='id'
					columns={columns}
					dataSource={warehouses}
					bordered
					pagination={false}
					scroll={tableScroll}
				/>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>

			<AddressModal onAfterSave={refresh} />
		</div>
	);
};

export default Addresses;
