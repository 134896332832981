import { useTranslation } from 'react-i18next';
import { SingleColumnTable } from './single-column-table';
import { useMemo } from 'react';

export const CargoInsuranceExcludedCommoditiesTable = () => {
	const { t, i18n } = useTranslation();

	// const column1 = [
	// 	{ text: t('Ammunition, weapons, explosives') },
	// 	{ text: t('Antiques, works of art and silverware') },
	// 	{
	// 		text: t(
	// 			'Any means of non-cash payment (e.g. cheques, ready to use credit-cards, fiscal stamps, securities, negotiable instruments, treasury notes and the like)',
	// 		),
	// 	},
	// 	{
	// 		text: t(
	// 			'Any cargo whilst being transported under its own power or whilst being towed on its own axle (e.g. motor vehicles, motor cycles, caravans, horse boxes, trailered boats)',
	// 		),
	// 	},
	// 	{ text: t('Blood and/or blood plasma') },
	// 	{ text: t('Bulk dry and bulk liquid goods, including those carried in flexitanks') },
	// 	{ text: t('Bullion and all precious metals') },
	// 	{ text: t('Cement, cement products') },
	// 	{ text: t('Cigarettes, cigars and other finished tobacco products') },
	// 	{ text: t('Dangerous goods class 1, 6.2, 7, 9, UN3111-UN3120, UN3231-UN3240, UN3507') },
	// 	{ text: t('Designs, patterns, plans, deeds, manuscripts and all other documents') },
	// ];

	// const column2 = [
	// 	{ text: t('Furs, fur garments') },
	// 	{ text: t('Household goods & personal effects') },
	// 	{ text: t('Jewelry') },
	// 	{ text: t('Livestock, bloodstock, living creatures & living botanicals') },
	// 	{ text: t('Loose precious gemstones') },
	// 	{ text: t('Money of every description (e.g. cash, bank notes, coins, currency notes)') },
	// 	{ text: t('Perishable & temperature sensitive commodities') },
	// 	{ text: t('Prototypes and one of a kind interests') },
	// 	{ text: t('Stamps, tickets and the like') },
	// 	{ text: t('Traded metals such as copper, tin, etc. in pure form') },
	// 	{ text: t('Unpacked / unprotected steel (such as pipes, coils, bars, sheet, etc.)') },
	// ];

	const columns = useMemo(
		() => [
			{
				text: t(
					'Cash and any non-cash payment instruments, including checks, notes, documents, securities, letters of credit, files, records, ledgers, drawings, and passports',
				),
			},
			{
				text: t(
					'Goods transported under their own power or towed by their own axles (including motor vehicles, motorcycles, RVs, carriages, trailers and ships, etc.)',
				),
			},
			{ text: t('Bulk dry and liquid goods, including those transported in container liners') },
			{ text: t('Cigarettes, cigars, and other tobacco products') },
			{ text: t('Furs and fur garments') },
			{
				text: t(
					'Artworks, gold, silver, jewelry, watches valued over RMB 10,000, antiques, musical instruments, and jade artifacts',
				),
			},
			{
				text: t(
					'Furniture constructed from precious woods (such as rosewood, Huanghuali, and red sandalwood)',
				),
			},
			{
				text: t(
					'Goods with self-ignition properties, such as fish meal, rapeseed cake, sweet potato chips, peanuts, various grains, feed, and raw sugar',
				),
			},
			{
				text: t(
					'Precision instruments or equipment lacking repair or appraisal capabilities and relevant components within mainland China (Note: Any instrument or equipment meeting one of the following criteria shall be classified as precision instruments or equipment: a. Special and stringent requirements for balance, stability, shock resistance, dust resistance, and tilting during transport; b. If damaged, spare parts cannot be procured, necessitating return to the original manufacturer abroad for repair or deemed irreparable; c. Individual instrument or equipment value exceeding RMB 2 million)',
				),
			},
			{
				text: t(
					'Hazardous materials, flammable and explosive substances, satellites, weapons, military supplies, and finished vehicles',
				),
			},
			{
				text: t(
					'Animals, plants, seafood, fresh products, and items requiring refrigerated transport',
				),
			},
			{ text: t('Giveaway, compensation materials, and aid supplies') },
			{
				text: t(
					'All categories of pharmaceuticals (including traditional Chinese medicine and Western medicine, serums, vaccines, blood products, etc.)',
				),
			},
			{
				text: t(
					'Logs, steel, ores, and mineral sands; cargo on deck (with the exception of container transport)',
				),
			},
			{ text: t('Goods transported unpackaged or in bulk') },
			{ text: t('Refurbished, in-use, second-hand, or used items') },
			{
				text: t(
					'Fragile items or items containing fragile components, such as glass, ceramics, lamps, marble, solar devices, and LCD screens (For fragile goods, only loss of items is covered; damage and other concerns are excluded)',
				),
			},
			{
				text: t(
					'Losses occurring during temporary storage of goods in transit (at any stage, except for airport and terminal yards) due to fire, explosion, or natural disasters',
				),
			},
			{
				text: t(
					'Losses arising from fire or explosion during the transportation of battery products',
				),
			},
			{
				text: t(
					'Losses and costs incurred due to shipment cancellations for goods destined for Amazon FBA warehouses or Walmart WFS warehouses, as indicated on the official website (unless the carrier provides proof of the status of the goods)',
				),
			},
			{
				text: t(
					'Losses and costs resulting from shipment cancellations during last-mile delivery (when the official carrier tracking indicates "shipment canceled", this will be considered a cancellation)',
				),
			},
			{
				text: t(
					"Losses and costs associated with the return of goods to the sender's address due to the recipient's failure to accept delivery properly",
				),
			},
		],
		[t],
	);

	// 根据语言调整行数，保持尽可能的对齐
	const column1 = useMemo(
		() => (i18n.language === 'zh-CN' ? columns.slice(0, 11) : columns.slice(0, 13)),
		[i18n.language, columns],
	);
	const column2 = useMemo(
		() => (i18n.language === 'zh-CN' ? columns.slice(11) : columns.slice(13)),
		[i18n.language, columns],
	);

	return (
		<div className='w-full h-full flex items-start gap-[16px]'>
			<SingleColumnTable dataSource={column1} />

			<SingleColumnTable dataSource={column2} />
		</div>
	);
};
