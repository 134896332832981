import { getPrepayOrders } from '@/services/apis/get-prepay-orders';
import { GetPrepayOrdersProps } from '@/services/apis/get-prepay-orders/interface';
import { usePagination } from 'ahooks';
import { message } from '@/utils/message';

export const useGetPrepayOrders = (options?: Record<any, any>) => {
	const res = usePagination(
		async ({ current, pageSize }, props?: Partial<GetPrepayOrdersProps>) => {
			const res = await getPrepayOrders({
				params: {
					page: current || props?.params?.page,
					per_page: pageSize || props?.params?.per_page,
					...props?.params,
				},
			});

			return {
				list: res?.data?.data ?? [],
				total: res?.data?.meta?.total,
			};
		},
		{
			// manual: true,
			onError: (error) => {
				// @ts-ignore
				message.error(`${error.response?.data?.message ?? error.message}`);
			},
			...options,
		},
	);

	return {
		...res,
		data: res.data?.list ?? [],
	};
};
