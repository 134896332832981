import { useGlobalContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import dayjs, { Dayjs } from 'dayjs';

export const useIsPickupDateDisabled = (config: { whiteListType: 'ltl' | 'ftl' }) => {
	const { cacheContext } = useGlobalContext();

	const holidays = cacheContext.holidays;

	const isWhiteList =
		config.whiteListType === 'ltl'
			? cacheContext.ltlWhiteList
			: config.whiteListType === 'ftl'
				? cacheContext.ftlWhiteList
				: { is_exist: false };

	/**
	 * 当 white list 或 holidays 未加载完，回直接返回
	 */
	const isPickupDateDisabled = useMemoizedFn((current: Dayjs) => {
		// 测试用例
		// const laCurrent = dayjs().tz('America/Los_Angeles').set('hour', 11)
		// const laCurrent = dayjs().tz('America/Los_Angeles').set('hour', 13)
		// console.log(`laCurrent ${laCurrent.format('YYYY-MM-DD HH')}`)

		const laCurrent = dayjs().tz('America/Los_Angeles');
		const currentFormate = current.format('YYYY-MM-DD');

		// 不允许选择过去的日期，也不允许选择当天
		if (current.isBefore(laCurrent)) {
			return true;
		}

		// 不允许选择周六日
		if (current.day() === 0 || current.day() === 6) {
			return true;
		}

		// 不允许节假日
		if (holidays?.includes(currentFormate)) {
			return true;
		}

		// 不在名单中 并且 la 时间已经过了当天12点，不可选择第二天
		// 如果 la 时间是周五过了12点/周六/周日，则下一个周一不可选
		const laNextDayFormate = laCurrent.add(1, 'day').format('YYYY-MM-DD');
		const laNextMondayFormate = laCurrent.add(6, 'day').day(1).format('YYYY-MM-DD');
		if (!isWhiteList?.is_exist) {
			if (
				[1, 2, 3, 4].includes(laCurrent.day()) &&
				currentFormate === laNextDayFormate &&
				laCurrent.hour() >= 12
			) {
				return true;
			}
			if (
				laCurrent.day() === 5 &&
				laCurrent.hour() >= 12 &&
				currentFormate === laNextMondayFormate
			) {
				return true;
			}
			if ([6, 0].includes(laCurrent.day()) && currentFormate === laNextMondayFormate) {
				return true;
			}
		}

		return false;
	});

	return {
		isPickupDateDisabled,
	};
};
