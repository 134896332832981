import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const DeliveryStatement = () => {
	const { t } = useTranslation();

	return (
		<Popover
			title={t('E-commerce warehouse delivery statement')}
			content={
				<>
					<div>
						{t(
							'To ensure the smooth dispatch of your goods, please note that if your delivery warehouse is an e-commerce warehouse, such as Walmart, Amazon, TikTok, Wayfair, eBay, etc., then the quotes on this platform are not applicable. Please contact your dedicated account manager for an accurate quote.',
						)}
					</div>
					<div>
						{t(
							"Please confirm that the delivery warehouse on your order can receive goods following the normal standard process. If the warehouse requires you to schedule appointments before delivery or has its own special receiving procedures, this platform's quotation service may not apply. Kindly please consult your dedicated account manager for further details.",
						)}
					</div>
				</>
			}
			overlayClassName='max-w-[50vw]'
		>
			<QuestionCircleOutlined className=' cursor-pointer' />
		</Popover>
	);
};
