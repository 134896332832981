import { IntermodalRegion } from '@/interfaces/intermodal-region.interface';
import { getIntermodalRegions } from '@/services/apis';
import { getHolidays } from '@/services/apis/get-holidays';
import { getIsWhitelist } from '@/services/apis/get-is-whitelist';
import { makeAutoObservable, runInAction } from 'mobx';

class CacheContext {
	constructor() {
		makeAutoObservable(this);
	}

	private _fetching = false;

	private _intermodalRegions: IntermodalRegion[] = [];

	private _holidays: string[] = [];

	private _ltlWhiteList: { is_exist: boolean } = { is_exist: false };

	private _ftlWhiteList: { is_exist: boolean } = { is_exist: false };

	get fetching() {
		return this._fetching;
	}

	set fetching(fetching: typeof this._fetching) {
		runInAction(() => {
			this._fetching = fetching;
		});
	}

	get intermodalRegions() {
		return this._intermodalRegions;
	}

	set intermodalRegions(intermodalRegions: typeof this._intermodalRegions) {
		runInAction(() => {
			this._intermodalRegions = intermodalRegions;
		});
	}

	get holidays() {
		return this._holidays;
	}

	set holidays(holidays: typeof this._holidays) {
		runInAction(() => {
			this._holidays = holidays;
		});
	}

	get ltlWhiteList() {
		return this._ltlWhiteList;
	}

	set ltlWhiteList(ltlWhiteList: typeof this._ltlWhiteList) {
		runInAction(() => {
			this._ltlWhiteList = ltlWhiteList;
		});
	}

	get ftlWhiteList() {
		return this._ftlWhiteList;
	}

	set ftlWhiteList(ftlWhiteList: typeof this._ftlWhiteList) {
		runInAction(() => {
			this._ftlWhiteList = ftlWhiteList;
		});
	}

	private async fetchIntermodalRegions() {
		const res = await getIntermodalRegions();
		this.intermodalRegions = res.data.data;
	}

	private async fetchHolidays() {
		const res = await getHolidays();
		this.holidays = res.data;
	}

	private async fetchLTLWhitelist() {
		const ltlRes = await getIsWhitelist({ params: { type: 'ltl' } });
		this.ltlWhiteList = ltlRes?.data;
	}

	private async fetchFTLWhitelist() {
		const ftlRes = await getIsWhitelist({ params: { type: 'ftl' } });
		this.ftlWhiteList = ftlRes?.data;
	}

	public async init() {
		await Promise.allSettled([
			this.fetchIntermodalRegions(),
			this.fetchHolidays(),
			this.fetchLTLWhitelist(),
			this.fetchFTLWhitelist(),
		]);
	}
}

export const cacheContext = new CacheContext();
