import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { MapProvider } from 'react-map-gl';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'antd/dist/reset.css';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'mapbox-gl/dist/mapbox-gl.css';
import ErrorFallback from './components/error-fallback/index.tsx';
import Address from './pages/address/index.tsx';
import AgentDrayOrders from './pages/agent-dray-orders/index.tsx';
import AgentFtlOrders from './pages/agent-ftl-orders/index.tsx';
import AgentLtlOrders from './pages/agent-ltl-orders/index.tsx';
import AgentUsers from './pages/agent-users/index.tsx';
import AgentWallet from './pages/agent-wallet/index.tsx';
import ApiDoc from './pages/api/index.tsx';
import ErrorTest from './pages/errortest/index.tsx';
import FtlShipmentBol from './pages/ftl-bol/index.tsx';
import { FTLGetRates } from './pages/ftl-get-rates/index.tsx';
import FTLOrders from './pages/ftl-orders/index.tsx';
import FtlQuoteHistory from './pages/ftl-quote-history/index.tsx';
import Login from './pages/login/index.tsx';
import LTLOrders from './pages/ltl-orders/index.tsx';
import LtlQuoteHistory from './pages/ltl-quote-history/index.tsx';
import { LTLSearchRate } from './pages/ltl-search-rate/index.tsx';
import OrderDetail from './pages/order-detail/index.tsx';
import OrderDetailContent from './pages/order-detail/order-detail-content.tsx';
import Order from './pages/order/index.tsx';
import PrepayOrder from './pages/prepay-order/index.tsx';
import Project from './pages/project/index.tsx';
import Projects from './pages/projects/index.tsx';
import RateRequest from './pages/rate-request/index.tsx';
import { Book } from './pages/search-rate/book/index.tsx';
import { SearchRate } from './pages/search-rate/index.tsx';
import { ShipmentOrder } from './pages/shipment-order/index.tsx';
import App from './App.tsx';
import i18n from './i18n/index.ts';
import './index.css';
import { AgentContext } from './services/contexts/agent/index.tsx';
import {
	BookContext,
	FTLContext,
	GlobalContext,
	LTLContext,
	OrderContext,
	RequestedRateContext,
	SearchRateContext,
} from './services/contexts/index.tsx';
import { ShipmentOrderContext } from './services/contexts/shipment-order/index.tsx';
import { initSentry } from './services/sentry/init.ts';

// 加载插件
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);

initSentry();

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const ErrorFallbackComponent = import.meta.env.PROD ? <ErrorFallback /> : undefined;

const router = sentryCreateBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: 'search-rate',
				element: <SearchRate />,
			},
			{
				path: 'apidoc/:version',
				element: <ApiDoc />,
			},
			{
				path: 'rate-request',
				element: (
					<RequestedRateContext>
						<RateRequest />
					</RequestedRateContext>
				),
			},
			{
				path: 'search-rate/book',
				element: (
					<SearchRateContext>
						<BookContext>
							<Book />
						</BookContext>
					</SearchRateContext>
				),
			},
			{
				path: 'orders',
				element: (
					<OrderContext>
						<Order />
					</OrderContext>
				),
			},
			{
				path: 'prepay-orders',
				element: (
					<OrderContext>
						<PrepayOrder />
					</OrderContext>
				),
			},
			{
				path: 'orders/:orderId',
				element: (
					<OrderContext>
						<OrderDetail />
					</OrderContext>
				),
				children: [
					{
						path: 'containers/:containerId',
						element: <OrderDetailContent />,
					},
				],
			},
			{
				path: 'addresses',
				element: <Address />,
			},
			{
				path: 'projects',
				element: <Projects />,
			},
			{
				path: 'projects/:projectId',
				element: <Project />,
			},
			{
				path: 'errortest',
				element: <ErrorTest />,
			},
			{
				path: 'ltl-search-rate',
				element: (
					<LTLContext>
						<LTLSearchRate />
					</LTLContext>
				),
			},
			{
				path: 'ltl-orders',
				element: (
					<LTLContext>
						<LTLOrders />
					</LTLContext>
				),
			},
			{
				path: 'ltl-orders/:orderId',
				element: (
					<ShipmentOrderContext>
						<ShipmentOrder />
					</ShipmentOrderContext>
				),
			},
			{
				path: 'agent-dray-orders',
				element: (
					<OrderContext>
						<AgentDrayOrders />
					</OrderContext>
				),
			},
			{
				path: 'agent-ltl-orders',
				element: (
					<OrderContext>
						<AgentLtlOrders />
					</OrderContext>
				),
			},
			{
				path: 'agent-ftl-orders',
				element: (
					<OrderContext>
						<AgentFtlOrders />
					</OrderContext>
				),
			},
			{
				path: 'agent-users',
				element: (
					<AgentContext>
						<AgentUsers />
					</AgentContext>
				),
			},
			{
				path: 'agent-wallet',
				element: (
					<AgentContext>
						<AgentWallet />
					</AgentContext>
				),
			},
			{
				path: 'ltl-quote-history',
				element: <LtlQuoteHistory />,
			},
			{
				path: 'ftl-get-rate',
				element: (
					<FTLContext>
						<FTLGetRates />
					</FTLContext>
				),
			},
			{
				path: 'ftl-quote-history',
				element: <FtlQuoteHistory />,
			},
			{
				path: 'ftl-orders',
				element: (
					<FTLContext>
						<FTLOrders />
					</FTLContext>
				),
			},
			{
				path: 'ftl-orders/:orderId',
				element: (
					<ShipmentOrderContext>
						<ShipmentOrder />
					</ShipmentOrderContext>
				),
			},
			{
				path: 'ftl-bol',
				element: <FtlShipmentBol />,
			},
		],
		errorElement: ErrorFallbackComponent,
	},
	{
		path: 'login',
		element: <Login />,
		errorElement: ErrorFallbackComponent,
	},
]);

ReactDOM.createRoot(document.getElementById('root')).render(
	// <React.StrictMode>
	<I18nextProvider i18n={i18n}>
		<GlobalContext>
			<MapProvider>
				<Sentry.ErrorBoundary fallback={() => ErrorFallbackComponent} showDialog>
					<RouterProvider router={router} />
				</Sentry.ErrorBoundary>
			</MapProvider>
		</GlobalContext>
	</I18nextProvider>,
	// </React.StrictMode>,
);
