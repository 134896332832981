import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Pagination, Select, Space, Table, TableProps, Tag } from 'antd';
import * as _ from 'lodash';
import { RequestedRatesResData } from '@/services/apis/get-requested-rates/interface';
import { useGlobalContext } from '@/services/contexts';
import '@/styles/no-cell-border-table.less';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { useDebounceFn, useMemoizedFn, useMount, useSetState } from 'ahooks';
import { observer } from 'mobx-react-lite';
import qs from 'qs';
import RejectionReason from './components/rejection-reason';
import CenterSpin from '@/components/center-spin';
import { Gap } from '@/components/gap';
import useRequestedRatesParams, { RequestedRatesQuery } from './hooks/use-requested-rates-params';
import { apiHooks } from '@/hooks/apis';

const RateRequest = observer(() => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { cacheContext } = useGlobalContext();

	const { parsedSearchParams, changeSearchParams } = useRequestedRatesParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Omit<RequestedRatesQuery, 'per_page' | 'page'>
	>({
		to_city_id: parsedSearchParams.to_city_id ?? undefined,
		intermodal_region_id: parsedSearchParams.intermodal_region_id ?? undefined,
		state: parsedSearchParams.state ?? [],
		cargo_types: parsedSearchParams.cargo_types ?? [],
	});

	const {
		runAsync: getRequestedRates,
		data: requestedRates,
		loading: requestedRatesLoading,
		pagination,
	} = apiHooks.useGetRequestedRates({
		refreshDeps: [queriesWithoutPagination],
		refreshDepsAction: () => {
			getRequestedRates(
				{ current: 1, pageSize },
				{
					params: {
						...queriesWithoutPagination,
					},
				},
			);
		},
	});

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns: TableProps<RequestedRatesResData>['columns'] = [
		{
			title: t('Date'),
			dataIndex: 'created_at',
			width: 180,
			fixed: 'left',
		},
		{
			title: t('Destination'),
			dataIndex: ['to_city', 'full_name'],
			width: 200,
		},
		{
			title: `${t('Port')}/${t('Ramp')}`,
			dataIndex: ['intermodal_region', 'name'],
			width: 200,
		},
		{
			title: t('Cargo type'),
			dataIndex: 'cargo_types',
			width: 120,
			render: (value) => {
				if (!value) return '/';
				const components = [];
				if (value.is_dg) components.push(<Tag>{t('DG')}</Tag>);
				if (value.is_reefer) components.push(<Tag>{t('RF')}</Tag>);
				if (value.is_overweight) components.push(<Tag>{t('OW')}</Tag>);
				if (components.length === 0) return null;
				if (components.length === 1) return components[0];
				return <Space>{components.map((c) => c)}</Space>;
			},
		},
		{
			title: t('Memo'),
			dataIndex: 'memo',
			width: 200,
		},
		{
			title: t('Actions'),
			key: 'actions',
			width: 120,
			fixed: 'right',
			render: (_, record) => (
				<Button
					type='link'
					className='px-0'
					onClick={() =>
						navigate(
							`/search-rate?${qs.stringify({
								intermodal_region_id: record.intermodal_region_id,
								to_city_id: record.to_city_id,
							})}`,
						)
					}
				>
					{t('View')}
				</Button>
			),
		},
	];

	const handleChange = useMemoizedFn((newQueries: RequestedRatesQuery) => {
		console.log(`[handleChange] newQueries`, newQueries);

		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) =>
				['to_city_id', 'intermodal_region_id', 'state', 'cargo_types'].includes(k),
			)
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		handleChange({
			page,
			per_page: pageSize,
		});
	});

	const searchCity = apiHooks.useSearchCity();
	const { run: handleSearchCity } = useDebounceFn(
		async (searchText: string) => {
			let fetchIndex = 0;

			const _handleSearch = async (searchText: string) => {
				if (searchText === '') return;
				fetchIndex += 1;
				const _fetchIndex = fetchIndex;
				await searchCity.runAsync({ path: { cityName: searchText } });
				if (_fetchIndex !== fetchIndex) return;
			};

			return _handleSearch(searchText);
		},
		{ wait: 500 },
	);

	useMount(async () => {
		await getRequestedRates(
			{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
			{
				params: {
					...queriesWithoutPagination,
				},
			},
		);
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	const CustomTableRow = ({ children, ...props }) => {
		const record = requestedRates.find((r) => r.id == props['data-row-key']);
		const reason = record?.rejected_reason ?? '';
		if (!record) return null;
		if (record.state !== 'rejected') return <tr {...props}>{children}</tr>;
		return (
			<>
				<tr {...props}>{children}</tr>
				<tr {...props} data-row-key={props['data-row-key'] + 'rejection'}>
					<td colSpan={6} className='ant-table-cell'>
						<RejectionReason reason={reason} />
					</td>
				</tr>
			</>
		);
	};

	return (
		<div className='box-border h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t('Rate Request')}</div>
				{requestedRatesLoading && <LoadingOutlined />}
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px] flex items-center gap-[12px]'>
				<div className='box-border p-[0_12px] h-[42px] w-[194px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<div></div>
					<Select
						value={queriesWithoutPagination.intermodal_region_id}
						suffixIcon={null}
						showSearch
						optionFilterProp='label'
						variant='borderless'
						options={cacheContext.intermodalRegions.map((i) => ({ label: i.name, value: i.id }))}
						placeholder={t('Port') + '/' + t('Ramp')}
						allowClear
						className='w-full'
						onChange={(value) => handleChange({ intermodal_region_id: value })}
					/>
				</div>

				<div className=' box-border p-[0_12px] h-[42px] min-w-[220px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<div></div>
					<Select
						value={queriesWithoutPagination.to_city_id}
						suffixIcon={null}
						mode='multiple'
						showSearch
						filterOption={false}
						variant='borderless'
						maxTagCount='responsive'
						options={searchCity.data.map((c) => ({ label: c.full_name, value: c.id }))}
						placeholder={t(`Destination`)}
						allowClear
						className='w-full'
						loading={searchCity.loading}
						notFoundContent={searchCity.loading ? <CenterSpin size='small' /> : null}
						onChange={(value) => handleChange({ to_city_id: value })}
						onSearch={handleSearchCity}
					/>
				</div>

				<div className=' box-border p-[0_12px] h-[42px] min-w-[220px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<div></div>
					<Select
						value={queriesWithoutPagination.state}
						suffixIcon={null}
						mode='multiple'
						showSearch
						optionFilterProp='label'
						variant='borderless'
						options={['completed', 'in_progress', 'rejected'].map((s) => ({
							label: t(_.startCase(s)),
							value: s,
						}))}
						maxTagCount='responsive'
						placeholder={t('Status')}
						allowClear
						className='w-full'
						onChange={(value) => handleChange({ state: value })}
					/>
				</div>

				<div className=' box-border p-[0_12px] h-[42px] min-w-[200px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<div></div>
					<Select
						value={queriesWithoutPagination.cargo_types}
						suffixIcon={null}
						mode='multiple'
						showSearch
						optionFilterProp='label'
						variant='borderless'
						options={[
							{ label: t('DG'), value: 2 },
							{ label: t('RF'), value: 3 },
							{ label: t('OW'), value: 4 },
						]}
						maxTagCount='responsive'
						placeholder={t('Cargo type')}
						allowClear
						className='w-full'
						onChange={(value) => handleChange({ cargo_types: value })}
					/>
				</div>

				<Button
					type='link'
					icon={<SyncOutlined />}
					onClick={() =>
						handleChange({
							intermodal_region_id: undefined,
							to_city_id: undefined,
							state: [],
							cargo_types: [],
						})
					}
				>
					{<span className='body-3-m text-primary-regular'>{t(`Reset`)}</span>}
				</Button>
			</div>

			<Gap height='24px' />

			{/* table */}
			<div className='flex-auto'>
				<Table
					className='no-cell-border-table'
					rowKey='id'
					columns={columns}
					dataSource={requestedRates}
					bordered
					components={{
						body: { row: CustomTableRow },
					}}
					pagination={false}
					scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 321px)' }}
				/>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>
		</div>
	);
});

export default RateRequest;
