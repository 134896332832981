import { getWarehouses } from '@/services/apis';
import { GetWarehousesProps } from '@/services/apis/get-warehouses/interface';
import { usePagination } from 'ahooks';
import { message } from '@/utils/message';

export const useGetWarehouses = (options?: Record<any, any>) => {
	const res = usePagination(
		async ({ current, pageSize }, props: GetWarehousesProps) => {
			if (!props) {
				return {
					list: [],
					total: 0,
				};
			}

			const response = await getWarehouses({
				params: {
					...props.params,
					page: current,
					per_page: pageSize,
				},
			});

			return {
				list: response?.data?.data ?? [],
				total: response?.data?.meta?.total,
			};
		},
		{
			onError: (error) => {
				// @ts-expect-error Types no match.
				message.error(`${error?.response?.data?.message ?? error.message}`);
			},
			...options,
		},
	);

	return {
		...res,
		data: res.data?.list ?? [],
	};
};
