import { useTranslation } from 'react-i18next';
import { Drawer } from 'antd';
import AddFundsDrawerForm from './add-funds-drawer-form';

const AddFundsDrawer = ({
	open,
	onClose,
	refreshPrepayOrders,
}: {
	open: boolean;
	onClose: () => void;
	refreshPrepayOrders: () => void;
}) => {
	const { t } = useTranslation();

	return (
		<Drawer width={894} open={open} onClose={onClose} title={t('Add Funds')}>
			<AddFundsDrawerForm onCancel={onClose} refreshPrepayOrders={refreshPrepayOrders} />
		</Drawer>
	);
};

export default AddFundsDrawer;
