import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import InvoiceIcon from '@/icons/invoice-icon';
import { Order } from '@/interfaces/order.interface';
import Empty from '@/components/empty';
import { BillContent } from '@/pages/order-detail/order-detail-container/components/billing/bill-content';
import { Invoice } from '@/interfaces/invoice.interface';

const TableExpandedRowInvoicesTab = ({ order }: { order: Order }) => {
	const { t } = useTranslation();

	const [currentInvoice, setCurrentInvoice] = useState<Invoice | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = (invoice: Invoice) => () => {
		setCurrentInvoice(invoice);
		setIsModalOpen(true);
	};
	const handleCloseModal = () => setIsModalOpen(false);

	if (
		!order.invoices ||
		order.invoices.length === 0 ||
		order.invoices.flatMap((i) => i.container_numbers ?? []).length === 0
	) {
		return <Empty />;
	}

	return (
		<div className='grid grid-cols-2 gap-x-[50px] gap-y-[8px]'>
			{order.invoices.map((invoice) =>
				invoice.container_numbers.map((container) => (
					<Fragment key={`${invoice.id}-${container}`}>
						<div className='px-[8px] py-[6px] flex justify-between items-center bg-white'>
							{/* left */}
							<div className='flex'>
								{/* icon */}
								<InvoiceIcon className='mr-[10px]' />
								<div>
									<div className='mb-[2px] body-4-b'>{container}</div>
									<div className='foot-note-1-m text-[#97999C]'>{invoice.due_at}</div>
								</div>
							</div>

							{/* middle */}
							<div className='body-4-b'>${invoice.amount_total}</div>

							{/* right */}
							<span className='text-[#367cf2] cursor-pointer' onClick={handleOpenModal(invoice)}>
								{t('Review')}
							</span>
						</div>
					</Fragment>
				)),
			)}
			{isModalOpen && currentInvoice && (
				<Modal
					open={isModalOpen}
					width={720}
					centered
					title={t('Review')}
					onCancel={handleCloseModal}
					footer={<Button onClick={handleCloseModal}>{t('Close')}</Button>}
				>
					<BillContent invoice={currentInvoice} />
				</Modal>
			)}
		</div>
	);
};

export default TableExpandedRowInvoicesTab;
