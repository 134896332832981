import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Pagination, Space, Table, TableProps } from 'antd';
import { Quote, quoteBusinessStatusMap } from '@/interfaces/quote.interface';
import {
	calcTotalVolume,
	calcTotalWeight,
	generateNumberOfUnits,
} from '@/services/contexts/ltl/ltl.context';
import '@/styles/no-cell-border-table.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { FT3 } from '@/components/ft3';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { LTLFTLFilter } from '../ltl-orders/ltl-ftl-filter';
import { LTLSearchRate } from '../ltl-search-rate';
import useLtlQuoteHistoryParams, { QuoteHistoryQuery } from './use-ltl-quote-history-params';

const LTLQuoteHistory = observer(() => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useLtlQuoteHistoryParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Pick<QuoteHistoryQuery, 'business_status' | 'query' | 'sort_by' | 'sort_value'>
	>({
		sort_by: 'quote_at',
		sort_value: 'desc',
		business_status: parsedSearchParams.business_status ?? [],
		query: parsedSearchParams.query ?? '',
	});

	const {
		runAsync: getQuoteList,
		data: quoteList,
		loading: quoteListLoading,
		pagination,
	} = apiHooks.useGetQuoteList({
		refreshDeps: [queriesWithoutPagination],
		refreshDepsAction: () => {
			getQuoteList(
				{ current: 1, pageSize },
				{
					params: {
						...queriesWithoutPagination,
						business_status: (queriesWithoutPagination.business_status ?? [])
							.flatMap((i) => (i === '40' ? ['40', '60'] : [i]))
							.join(','),
					},
				},
			);
		},
	});

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns: TableProps<Quote>['columns'] = [
		{
			title: t('Quote#'),
			dataIndex: 'uid',
			width: 120,
			fixed: 'left',
		},
		{
			title: t('Origin'),
			dataIndex: ['pickup_zipcode'],
			width: 200,
			render: (_, record) => (
				<div>
					{record.pickup_zipcode && <div>{record.pickup_zipcode}</div>}
					<div>
						<span>{record.pickup_city_name ?? ''}</span>
						{record.pickup_city_name && record.pickup_state_name && <span>{`, `}</span>}
						<span>{record.pickup_state_name}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Destination'),
			dataIndex: ['destination_zipcode'],
			width: 200,
			render: (_, record) => (
				<div>
					{record.destination_zipcode && <div>{record.destination_zipcode}</div>}
					<div>
						<span>{record.destination_city_name ?? ''}</span>
						{record.destination_city_name && record.destination_state_name && <span>{`, `}</span>}
						<span>{record.destination_state_name}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Pallets/Volume/Weight'),
			dataIndex: ['items'],
			width: 200,
			render: (items: Quote['items']) => (
				<div>
					<div>{`${generateNumberOfUnits(items)}`}</div>
					<div>
						<span>{`${calcTotalVolume(items)} `}</span>
						<FT3 />
						<span>{`${calcTotalWeight(items)} lbs`}</span>
					</div>
				</div>
			),
		},
		{
			title: t('Status'),
			dataIndex: 'business_status',
			width: 120,
			render: (value) => t(quoteBusinessStatusMap[value]) ?? '',
		},
		{
			title: t('Quote Date'),
			dataIndex: ['quote_at'],
			width: 140,
			render: (value) => (value ? dayjs(value).format('YYYY-MM-DD') : ''),
		},
		{
			title: t(`Actions`),
			key: 'actions',
			width: 140,
			fixed: 'right',
			render: (_, record) => (
				<Space>
					<Button
						type='link'
						className='px-0'
						onClick={() => {
							changeSearchParams({ ...parsedSearchParams, quote_id: record.uid });
						}}
					>
						{t('View')}
					</Button>
					<Button
						type='link'
						className='px-0'
						onClick={() => {
							navigate(`/ltl-search-rate?quote_id=${record.uid}&type=copy`);
						}}
					>
						{t('Copy')}
					</Button>
				</Space>
			),
		},
	];

	const handleChange = useMemoizedFn((newQueries: QuoteHistoryQuery) => {
		console.log(`[handleChange] newQueries`, newQueries);

		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) =>
				['sort_by', 'sort_value', 'query', 'business_status', 'last_days'].includes(k),
			)
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		handleChange({
			page,
			per_page: pageSize,
		});
	});

	useMount(async () => {
		await getQuoteList(
			{ pageSize: parsedSearchParams.per_page ?? 20, current: parsedSearchParams.page ?? 1 },
			{
				params: {
					...queriesWithoutPagination,
					business_status: (queriesWithoutPagination.business_status ?? []).join(','),
				},
			},
		);
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	if (parsedSearchParams.quote_id) {
		return <LTLSearchRate />;
	}

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t('LTL Quote History')}</div>
				{quoteListLoading && <LoadingOutlined />}
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px]'>
				<LTLFTLFilter
					value={{
						query: queriesWithoutPagination.query,
						business_status: queriesWithoutPagination.business_status,
					}}
					statusFieldName='business_status'
					type='quote'
					statusOptions={Object.entries(quoteBusinessStatusMap)
						.filter(([value, label]) => `${value}` !== '0')
						.map(([value, label]) => ({ label: t(label), value: `${value}` }))}
					handleChange={(value) => handleChange(value)}
				/>
			</div>

			<Gap height='24px' />

			{/* table */}
			<div className='flex-auto'>
				<Table
					className='no-cell-border-table'
					rowKey='id'
					columns={columns}
					dataSource={quoteList}
					bordered
					pagination={false}
					scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 345px)' }}
				/>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>
		</div>
	);
});

export default LTLQuoteHistory;
