import { useLocalStorageState } from 'ahooks';
import { Alert } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type DurationUnitsObjectType = Partial<{
	[unit in Exclude<dayjs.UnitTypeLongPlural, 'dates'> | 'weeks']: number;
}>;

interface MaintenanceAnnouncementProps {
	/**
	 * 开始维护时的北京日期时间。北京日期时间和北美太平洋日期时间需要至少传入一个。
	 *
	 * Beijing date time when maintenance starts. Beijing date time and PST date time need to be passed into at least one.
	 *
	 * @example 2024/12/1 2PM
	 */
	beijingDateTime?: string;
	/**
	 * 开始维护的北美太平洋日期时间。北京日期时间和北美太平洋日期时间需要至少传入一个。
	 *
	 * PST date time when maintenance starts. Beijing date time and PST date time need to be passed into at least one.
	 *
	 * @example 2024/12/1 2PM
	 */
	pstDateTime?: string;
	/**
	 * 日期时间解析模板。
	 *
	 * Date time parse template.
	 *
	 * @default 'YYYY/MM/D hA'
	 */
	dateTimeParseTemplate?: string;
	/**
	 * 日期时间格式化模板。
	 *
	 * Date time format template.
	 *
	 * @default same year 'MM/D hA'
	 *
	 * @default not same year 'YYYY/MM/D hA'
	 */
	dateTimeFormatTemplate?: string;
	/**
	 * 维护持续时长。
	 *
	 * Maintenance duration.
	 *
	 * @example 2 hours { hours: 2 }
	 *
	 * @example 1 day 1 hour { days: 1, hours: 1 }
	 */
	duration: DurationUnitsObjectType;
}

const defaultDateTimeParseTemplate = 'YYYY/MM/D hA';
const defaultSameYearDateTimeFormatTemplate = 'MM/D hA';
const defaultNotSameYearDateTimeFormatTemplate = 'YYYY/MM/D hA';
const beijingTimezone = 'Asia/Shanghai';
const pstTimezone = 'America/Los_Angeles';

const MaintenanceAnnouncement = ({
	beijingDateTime,
	pstDateTime,
	duration,
	dateTimeParseTemplate = defaultDateTimeParseTemplate,
	dateTimeFormatTemplate,
}: MaintenanceAnnouncementProps) => {
	const { t } = useTranslation();

	// 至少传入一个日期时间
	if (!beijingDateTime && !pstDateTime) {
		throw new Error('Prop beijingDateTime or pstDateTime is required.');
	}
	// 必须传入持续时长
	if (!duration) {
		throw new Error('Prop duration is required.');
	}

	// 将日期时间转换成内部变量并进行相关计算
	let _beijingDateTime: dayjs.Dayjs;
	let _pstDateTime: dayjs.Dayjs;
	if (beijingDateTime) {
		_beijingDateTime = dayjs.tz(beijingDateTime, dateTimeParseTemplate, beijingTimezone);
		_pstDateTime = _beijingDateTime.tz(pstTimezone);
	} else {
		_pstDateTime = dayjs.tz(pstDateTime, dateTimeParseTemplate, pstTimezone);
		_beijingDateTime = _pstDateTime.tz(beijingTimezone);
	}

	// 将持续时长转换成内部变量并进行相关计算
	const _duration = dayjs.duration(duration);
	const _durationArray: string[] = [];
	if (_duration.days()) {
		const days = _duration.days();
		_durationArray.push(`${days} ${t(days === 1 ? 'day' : 'days')}`);
	}
	if (_duration.hours()) {
		const hours = _duration.hours();
		_durationArray.push(`${hours} ${t(hours === 1 ? 'hour' : 'hours')}`);
	}
	if (_duration.minutes()) {
		const minutes = _duration.minutes();
		_durationArray.push(`${minutes} ${t(minutes === 1 ? 'minute' : 'minutes')}`);
	}
	const _durationText = _durationArray.join(' '); // 直接使用 format 可能会出现 undefined

	// 是否同年，如果不同年，默认将年份也显示出来
	const isSameYear = dayjs().isSame(_pstDateTime, 'year');
	const _dateTimeFormatTemplate =
		dateTimeFormatTemplate ??
		(isSameYear ? defaultSameYearDateTimeFormatTemplate : defaultNotSameYearDateTimeFormatTemplate);

	// 记录关闭状态
	const storageKey = `maintenance-announcement-${_pstDateTime.format()}-${_durationText}-closed`;
	const [closed, setClosed] = useLocalStorageState(storageKey, {
		defaultValue: false,
	});
	const handleClose = useCallback(() => {
		setClosed(true);
	}, [setClosed]);

	// 计算当前时间是否在维护开始日期时间之后，若是，视为已经完成维护
	const timedOut = dayjs().tz(pstTimezone).isSameOrAfter(_pstDateTime);

	// 手动关闭，或已经完成维护，不再显示对应的提示
	if (closed || timedOut) {
		return null;
	}

	return (
		<Alert
			message={t(
				'The system will undergo maintenance on beijingDateTime Beijing Time / pstDateTime PST, estimated to last duration. Thank you for your support!',
				{
					beijingDateTime: _beijingDateTime.format(_dateTimeFormatTemplate),
					pstDateTime: _pstDateTime.format(_dateTimeFormatTemplate),
					duration: _durationText,
				},
			)}
			type='info'
			showIcon
			banner
			closable
			onClose={handleClose}
		></Alert>
	);
};

export default MaintenanceAnnouncement;
