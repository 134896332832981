import { LTLAddressType } from '@/interfaces/ltl-address-type.enum';
import { useLTLContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import { apiHooks } from '@/hooks/apis';
import { useIsPickupDateDisabled } from '@/hooks/use-is-pickup-date-disabled';
import { LaDayjs } from '@/utils/la-dayjs';
import { useGenerateAddress } from '../../use-generate-address';

interface AutofillData {
	pickup_city_name: string;
	pickup_state_name: string;
	pickup_zipcode: string;
	pickup_address1?: string;
	pickup_address2?: string;
	pickup_address_type: string;
	destination_city_name: string;
	destination_state_name: string;
	destination_zipcode: string;
	destination_address1?: string;
	destination_address2?: string;
	destination_address_type: string;
	estimated_pickup_date?: string;
	items?: any[];
	pickup_accessorials?: readonly (LTLAddressType | 'liftgate' | 'inside')[];
	destination_accessorials?: readonly (LTLAddressType | 'liftgate' | 'inside' | 'appointment')[];

	shipment_accessorials?: string[];
}

export const useLTLStepOneAIAutofill = () => {
	const ltlContext = useLTLContext();
	const { runAsync } = apiHooks.useGetLTLAutofill();
	const { runAsync: runImageAutofill } = apiHooks.useGetLTLAutofillFromImage();
	const { generateAddress } = useGenerateAddress();

	const { isPickupDateDisabled } = useIsPickupDateDisabled({ whiteListType: 'ltl' });

	const mapAddressTypeToEnum = (addressType: string): LTLAddressType => {
		switch (addressType?.toLowerCase()) {
			case 'residential':
				return LTLAddressType.RESIDENTIAL;
			case 'limitedaccess':
				return LTLAddressType.LIMITED_ACCESS;
			case 'tradeshow':
				return LTLAddressType.TRADESHOW;
			case 'business':
			default:
				return LTLAddressType.BUSINESS;
		}
	};

	const processAutofillData = async (data: AutofillData) => {
		// 检查必要字段
		const requiredFields = [
			'pickup_city_name',
			'pickup_state_name',
			'pickup_zipcode',
			'destination_city_name',
			'destination_state_name',
			'destination_zipcode',
		] as const;

		const missingFields = requiredFields.filter((field) => !data[field]);
		if (missingFields.length > 0) {
			console.error('Missing required fields:', missingFields);
			throw new Error('Failed to extract required information');
		}

		const pickupAddress = await generateAddress({
			city_name: data.pickup_city_name,
			state_name: data.pickup_state_name,
			zipcode: data.pickup_zipcode,
			address1: data.pickup_address1,
			address2: data.pickup_address2,
		});

		const destinationAddress = await generateAddress({
			city_name: data.destination_city_name,
			state_name: data.destination_state_name,
			zipcode: data.destination_zipcode,
			address1: data.destination_address1,
			address2: data.destination_address2,
		});

		if (pickupAddress) {
			ltlContext.pickupAddress = pickupAddress;
		}
		if (destinationAddress) {
			ltlContext.destinationAddress = destinationAddress;
		}

		ltlContext.pickUpAddressType = mapAddressTypeToEnum(data.pickup_address_type);
		ltlContext.destinationAddressType = mapAddressTypeToEnum(data.destination_address_type);

		ltlContext.stepOneFormValues = {
			pickup_date:
				!!data.estimated_pickup_date && !isPickupDateDisabled(LaDayjs(data.estimated_pickup_date))
					? LaDayjs(data.estimated_pickup_date)
					: undefined,
			items: (data.items || []).map((item) => ({ ...item, id: Math.random().toString() })),
			pickup_accessorials: (data.pickup_accessorials || []) as (
				| LTLAddressType
				| 'liftgate'
				| 'inside'
			)[],
			destination_accessorials: (data.destination_accessorials || []) as (
				| LTLAddressType
				| 'liftgate'
				| 'inside'
			)[],
			shipment_accessorials: (data.shipment_accessorials || []).filter(
				(acc) => acc === 'hazmat',
			) as ['hazmat'],
		};

		ltlContext.calcItemFreightClass();
	};

	const handleAIAutofill = useMemoizedFn(async (content: string) => {
		try {
			const response = await runAsync({ data: { content } });
			// 数据解析方式
			let data: AutofillData;

			if (typeof response.data === 'string') {
				data = JSON.parse(response.data) as AutofillData;
			} else if (response.data && typeof response.data === 'object') {
				data = response.data as AutofillData;
			} else {
				throw new Error('Invalid response format');
			}

			await processAutofillData(data);
		} catch (err) {
			console.error('Text autofill error:', err);
			throw err;
		}
	});

	const handleAIAutofillFromImage = useMemoizedFn(async (parsedData: any) => {
		try {
			let data: AutofillData;

			if (typeof parsedData === 'string') {
				data = JSON.parse(parsedData) as AutofillData;
			} else if (parsedData && typeof parsedData === 'object') {
				data = parsedData as AutofillData;
			} else {
				throw new Error('Invalid response format');
			}

			await processAutofillData(data);
		} catch (err) {
			console.error('Image autofill error:', err);
			throw err;
		}
	});

	return { handleAIAutofill, handleAIAutofillFromImage };
};
