import React from 'react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

export const initSentry = () => {
	Sentry.init({
		enabled: import.meta.env.PROD,
		dsn: 'https://c4420c7ebad5765ee5c79e31c363bdfb@o4506187848613888.ingest.sentry.io/4506191353282560',
		integrations: [
			new Sentry.BrowserTracing({
				// See docs for support of different versions of variation of react router
				// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			}),
			new Sentry.Replay(),
		],

		beforeSend: (event) => {
			// filter axios 401 error
			const exception = event.exception?.values?.[0];
			if (exception?.type === 'AxiosError' && exception?.value?.includes('401')) {
				return null;
			}
			return event;
		},

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [/^https:\/\/app\.drayeasy\.com/],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
};
