import accessTokenManager from '@/services/contexts/user/access-token-manager';
import axios from '../axios';
import { IsWhiteListProps, IsWhiteListRes } from './interface';

export const getIsWhitelist = async (props: IsWhiteListProps) => {
	return await axios.get<IsWhiteListRes>(`/tools/is-whitelist`, {
		params: props.params,
		headers: {
			Authorization: accessTokenManager.getAccessToken(),
		},
	});
};
