import { CSSProperties, memo } from 'react';
import { Card, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface NumberCardProps {
	title: string;
	number: number;
	tips?: string;
}

const CardStyle: CSSProperties = {
	width: 'calc(20vw - 80px)', // 考虑单行显示 5 个计算宽度
	minWidth: '216px', // 允许英文下完整显示标题
};

const NumberCard = memo(({ title, number, tips }: NumberCardProps) => {
	const formattedNumber = new Intl.NumberFormat('en-US').format(number);

	return (
		<Card style={CardStyle} className='shadow'>
			<strong>
				{title}{' '}
				{tips && (
					<Tooltip title={tips} placement='right'>
						<InfoCircleOutlined />
					</Tooltip>
				)}
			</strong>
			<h4>${formattedNumber}</h4>
		</Card>
	);
});

export default NumberCard;
