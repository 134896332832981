import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	DatePicker,
	Input,
	Pagination,
	Select,
	Space,
	Table,
	TableProps,
	Tooltip,
} from 'antd';
import DiversionIcon from '@/icons/diversion-icon';
import DiversionOutlinedIcon from '@/icons/diversion-outlined-icon';
import Search16pxIcon from '@/icons/search-16px-icon';
import { Order } from '@/interfaces/order.interface';
import { useGlobalContext } from '@/services/contexts';
import { CONTAINER_STATUS_MAP } from '@/services/contexts/container/constant';
import '@/styles/no-cell-border-table.less';
import { InfoCircleOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSetState } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import DuplicateOrderButton from './components/duplicate-order-button';
import SelectionToolbar from './components/selection-toolbar';
import TableExpandedRow from './components/table-expaneded-row';
import ViewDraftOrdersButton from './components/view-draft-orders-button';
import { ExpandIcon } from '@/components/expand-icon';
import { Gap } from '@/components/gap';
import OrderReleaseStatusTag from '@/components/order-release-status-tag';
import Risks from '@/components/risks';
import useOrdersParams, { OrdersQuery } from './hooks/use-orders-params';
import { apiHooks } from '@/hooks/apis';

const SORT_MAP = {
	ascend: 'asc',
	descend: 'desc',
};

const Orders = observer(({ mode }: { mode?: string }) => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = useOrdersParams();

	const [queriesWithoutPagination, setQueriesWithoutPagination] = useSetState<
		Omit<OrdersQuery, 'per_page' | 'page'>
	>({
		sort_by: undefined,
		sort_value: 'desc',
		query: parsedSearchParams.query ?? undefined,
		container_status: parsedSearchParams.container_status ?? [],
		created_at_start_date: parsedSearchParams.created_at_start_date ?? undefined,
		created_at_end_date: parsedSearchParams.created_at_end_date ?? undefined,
	});

	const {
		runAsync: getOrders,
		data: orders,
		loading: ordersLoading,
		pagination,
		refresh,
	} = (mode === 'agent' ? apiHooks.useGetAgentOrders : apiHooks.useGetOrders)({
		refreshDeps: [queriesWithoutPagination],
		refreshDepsAction: () => {
			getOrders(
				{
					current: 1,
					pageSize,
				},
				{
					params: {
						...queriesWithoutPagination,
					},
				},
			);
		},
	});

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns: TableProps<Order>['columns'] = [
		{
			title: t('MBL #'),
			dataIndex: 'mbl_number',
			width: 200,
			render: (value, record) => {
				const risks = [...new Set(record.containers.flatMap((c) => c.risks))];
				const Base = (
					<>
						<div>
							<span
								className='underline cursor-pointer'
								onClick={() =>
									handleExpandRow(expandedRowKeys.includes(record.id) ? false : true, record)
								}
							>
								{record.mbl_number}
							</span>
							<Risks risks={risks} />
						</div>
						<div className='body-4-r'>
							{t('To')} {record?.warehouse?.city?.full_name ?? '-'}
						</div>
					</>
				);
				if (!record.has_division) return Base;
				return (
					<Space align='center'>
						<div>{Base}</div>
						<Tooltip
							color='#fff2f0'
							title={
								<Space align='center'>
									<DiversionOutlinedIcon />
									<div className='text-black'>{t('Containers Diversion')}</div>
								</Space>
							}
						>
							<DiversionIcon />
						</Tooltip>
					</Space>
				);
			},
		},
		{
			title: t('User'),
			dataIndex: ['user', 'name'],
			width: 120,
		},
		{
			title: t('Container'),
			key: 'containers',
			width: 120,
			render: (value, record) => (
				<>
					<div>
						{record.container_delivered_counts}/{record.container_count}
					</div>
					<div className='body-4-r'>{t('delivered')}</div>
				</>
			),
		},
		{
			title: `${t('Final Port')} ETA`,
			dataIndex: 'final_port_eta',
			sorter: true,
			width: 160,
		},
		{
			title: t('Final Port'),
			dataIndex: ['final_port', 'name'],
			width: 160,
		},
		{
			title: (
				<Space>
					<div>{t('Release Status')}</div>
					<Tooltip title={t('RELEASE_STATUS_DESC')}>
						<InfoCircleOutlined />
					</Tooltip>
				</Space>
			),
			dataIndex: 'released_status',
			width: 160,
			render: (value) => <OrderReleaseStatusTag status={value} />,
		},
		{
			title: t('Order Date'),
			dataIndex: 'created_at',
			sorter: true,
			width: 140,
		},
		{
			key: 'Actions',
			title: t('Actions'),
			width: 200,
			fixed: 'right',
			render: (value, record) => <DuplicateOrderButton order={record} />,
		},
		Table.EXPAND_COLUMN,
	];

	const _columns = columns.filter((column) => !(mode != 'agent' && column.title === t('User')));

	const handleChange = useMemoizedFn((newQueries: OrdersQuery) => {
		console.log(`[handleChange] newQueries`, newQueries);

		if (Number.isInteger(newQueries.page) && Number.isInteger(newQueries.per_page)) {
			changePagination(newQueries.page, newQueries.per_page);
		} else if (
			Object.keys(newQueries).some((k) =>
				[
					'sort_by',
					'sort_value',
					'query',
					'container_status',
					'created_at_start_date',
					'created_at_end_date',
				].includes(k),
			)
		) {
			setQueriesWithoutPagination(newQueries);
		}

		changeSearchParams(newQueries);
	});

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		handleChange({
			page,
			per_page: pageSize,
		});
	});

	const handleChangeTable = useMemoizedFn<TableProps<Order>['onChange']>(
		(pagination, filters, sorter) => {
			handleChange({
				// @ts-expect-error type not match
				sort_by: sorter.field,
				// @ts-expect-error type not match
				sort_value: SORT_MAP[sorter.order],
			});
		},
	);

	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>(() => {
		// 如果 SearchParams 中有 order_id，那么需要保存它作为默认展开的行的 rowKey
		if (!parsedSearchParams.order_id) return [];
		const parsed = Number.parseInt(parsedSearchParams.order_id, 10);
		if (Number.isNaN(parsed)) return [];
		return [parsed];
	});
	const handleExpandRow = (expanded: boolean, record: Order) => {
		if (expanded) {
			setExpandedRowKeys([...expandedRowKeys, record.id]);
		} else {
			setExpandedRowKeys(expandedRowKeys.filter((k) => k !== record.id));
		}
	};
	const tableExpandable: TableProps<Order>['expandable'] = {
		expandedRowRender: (record) => <TableExpandedRow order={record} refresh={refresh} />,
		expandedRowKeys,
		onExpand: handleExpandRow,
		expandIcon: ExpandIcon,
	};

	const { userContext } = useGlobalContext();
	const __enable_project_feature = !!userContext.getUserInfo()?.__enable_project_feature;
	const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
	const handleClearSelection = () => {
		setSelectedRowKeys([]);
	};
	const tableRowSection: TableProps<Order>['rowSelection'] = {
		selectedRowKeys,
		onChange: (selectedRowKeys) => {
			setSelectedRowKeys(selectedRowKeys as number[]);
		},
	};

	useMount(async () => {
		await getOrders(
			{
				pageSize: parsedSearchParams.per_page ?? 20,
				current: parsedSearchParams.page ?? 1,
			},
			{
				params: {
					...queriesWithoutPagination,
				},
			},
		);
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	const tableRef: Parameters<typeof Table>[0]['ref'] = useRef(null);
	// 确定布局后滚动到指定订单
	useLayoutEffect(() => {
		// SearchParams 里面没有 order_id 或者 orders 为空时无需滚动，直接返回跳过执行
		if (!parsedSearchParams.order_id || orders.length === 0) return;
		// 找到对应的 Element
		const trElement = document.querySelector(
			`.ant-table-row[data-row-key="${parsedSearchParams.order_id}"]`,
		) as HTMLElement | null;
		// 如果 order_id 不合法，找不到对应的 Element，直接返回跳过执行
		if (!trElement) return;
		// 将指定订单滚动到表格最顶部
		// 如果使用 rowKey，会将制定订单滚动到表格最底部
		tableRef.current?.scrollTo({ top: trElement.offsetTop });
		// 滚动到指定订单后，清空 SearchParams 中的 order_id
		changeSearchParams({ order_id: undefined });
	}, [parsedSearchParams.order_id, orders]); // 只有 SearchParams.order_id 和 orders 有变化时触发

	return (
		<div className='relative h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t(`My Orders`)}</div>
				{ordersLoading && <LoadingOutlined />}
				<div className='flex-auto'></div>
				{mode !== 'agent' && <ViewDraftOrdersButton />}
			</div>

			<Gap height='36px' />

			{/* filters */}
			<div className='grow-0 shrink-0 w-full h-[42px] flex items-center gap-[12px] flex-wrap'>
				<Input
					value={queriesWithoutPagination.query}
					prefix={<Search16pxIcon />}
					placeholder={t('Search for MBL#, Container number...')}
					className='h-[42px] w-[300px] border-[1px] border-solid border-grey-04'
					onChange={(e) => handleChange({ query: e.target.value })}
				/>

				<div className='p-[0_12px] h-[42px] min-w-[320px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<Select
						value={queriesWithoutPagination.container_status}
						suffixIcon={null}
						mode='multiple'
						showSearch
						optionFilterProp='label'
						variant='borderless'
						options={Object.keys(CONTAINER_STATUS_MAP).map((key) => ({
							label: t(CONTAINER_STATUS_MAP[key]),
							value: key,
						}))}
						placeholder={t(`CNTR Status`)}
						allowClear
						maxTagCount='responsive'
						className='w-full'
						onChange={(value) => handleChange({ container_status: value })}
					/>
				</div>

				<div className='p-[0_12px] h-[42px] min-w-[176px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<DatePicker
						value={
							queriesWithoutPagination.created_at_start_date == null
								? queriesWithoutPagination.created_at_start_date
								: dayjs(queriesWithoutPagination.created_at_start_date, 'YYYY-MM-DD')
						}
						variant='borderless'
						allowClear
						placeholder={t('Order date to')}
						className='w-full'
						onChange={(dayjsInstance, dateString) => {
							if (dateString === '') {
								return handleChange({
									created_at_start_date: undefined,
								});
							}
							handleChange({
								created_at_start_date: Array.isArray(dateString) ? dateString[0] : dateString,
							});
						}}
					/>
				</div>

				<div className='p-[0_12px] h-[42px] min-w-[176px] border-[1px] border-solid border-grey-04 bg-white rounded-[4px] flex justify-center items-center'>
					<DatePicker
						value={
							queriesWithoutPagination.created_at_end_date == null
								? queriesWithoutPagination.created_at_end_date
								: dayjs(queriesWithoutPagination.created_at_end_date, 'YYYY-MM-DD')
						}
						variant='borderless'
						allowClear
						placeholder={t('Order date from')}
						className='w-full'
						onChange={(dayjsInstance, dateString) => {
							if (dateString === '') {
								return handleChange({
									created_at_end_date: undefined,
								});
							}
							handleChange({
								created_at_end_date: Array.isArray(dateString) ? dateString[0] : dateString,
							});
						}}
					/>
				</div>

				<Button
					type='link'
					icon={<SyncOutlined />}
					onClick={() =>
						handleChange({
							query: undefined,
							container_status: [],
							created_at_start_date: undefined,
							created_at_end_date: undefined,
						})
					}
				>
					{<span className='body-3-m text-primary-regular'>{t(`Reset`)}</span>}
				</Button>
			</div>

			<Gap height='24px' />

			{/* table */}
			<div className='flex-auto'>
				<Table
					ref={tableRef}
					className='no-cell-border-table'
					rowKey='id'
					columns={_columns}
					dataSource={orders}
					bordered
					scroll={{ scrollToFirstRowOnChange: true, x: 'max-content', y: 'calc(100vh - 345px)' }}
					expandable={tableExpandable}
					rowSelection={tableRowSection}
					pagination={false}
					onChange={handleChangeTable}
				/>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>

			{__enable_project_feature && (
				<SelectionToolbar selectedRowKeys={selectedRowKeys} clear={handleClearSelection} />
			)}
		</div>
	);
});

export default Orders;
