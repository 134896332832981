import { Gap } from '@/components/gap';

export interface FormItemTitleProps {
	content: string;
	required?: boolean;
	disabledGap?: boolean;
	extra?: React.ReactNode;
}

export const FormItemTitle = ({ content, required, disabledGap, extra }: FormItemTitleProps) => {
	return (
		<>
			<div className='body-3-r text-black whitespace-nowrap'>
				{content}
				{required && <span className=' text-updated-red'>*</span>}
				{extra}
			</div>
			{!disabledGap && <Gap height='8px' />}
		</>
	);
};
