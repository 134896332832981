import { getAccount } from '@/services/apis/get-prepay-orders';
import { useRequest } from 'ahooks';
import { message } from '@/utils/message';

export const useGetAccount = () => {
	const res = useRequest(getAccount);

	if (res.error) {
		// @ts-ignore
		message.error(`${res.error.response?.data?.message ?? res.error.message}`);
	}

	return {
		loading: res.loading,
		data: res.data?.data?.data,
		error: res.error,
	};
};
