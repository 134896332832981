import { useTranslation } from 'react-i18next';
import { Form, Input, InputNumber, Select } from 'antd';
import Delete24pxIcon from '@/icons/delete-24px-icon';
import { packageingTypes } from '@/interfaces/quote.interface';
import { ShipmentItem } from '@/interfaces/shipment.interface';
import { useLTLContext } from '@/services/contexts';
import { useMemoizedFn } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { FormItemTitle } from '../../form-item-title';
import { formItemWrapper } from '../../form-item-wrapper';

export const ItemInfo = observer(
	({ item, index }: { item: ShipmentItem & { id: string }; index: number }) => {
		const { t } = useTranslation();

		const ltlContext = useLTLContext();

		const handleDelete = useMemoizedFn(() => {
			if (ltlContext.stepOneFormValues.items.length <= 1) return;

			ltlContext.stepOneFormValues.items = ltlContext.stepOneFormValues.items.filter(
				(i) => i.id !== item.id,
			);
		});

		// 自定义校验规则，确保数字大于0
		const validatePositiveNumber = useMemoizedFn((rule, value) => {
			if (!value || value <= 0) {
				return Promise.reject(t(`Can't be 0`));
			}
			return Promise.resolve();
		});

		const validateEnglishNumberSymbol = useMemoizedFn((_: any, value: string) => {
			if (!value || value === '') return Promise.resolve();

			const isValid = /^[A-Za-z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/.test(value); // 校验是否只包含字母、数字、空格和符号

			if (!isValid) {
				return Promise.reject(new Error(t('Only English, numbers and symbols are allowed')));
			}

			const notOnlyNumbers = !/^\d+$/.test(value); // 校验是否不是纯数字

			if (!notOnlyNumbers) {
				return Promise.reject(new Error(t(`Cannot only contain numbers`)));
			}

			return Promise.resolve();
		});

		const turnIntoInt = useMemoizedFn(() => {
			ltlContext.stepOneFormValues.items = ltlContext.stepOneFormValues.items.map((item) => {
				return {
					...item,
					units: item.units ? Number(Number(item.units).toFixed(0)) : item.units,
					total_weight: item.total_weight
						? Number(Number(item.total_weight).toFixed(0))
						: item.total_weight,
					length: item.length ? Number(Number(item.length).toFixed(0)) : item.length,
					width: item.width ? Number(Number(item.width).toFixed(0)) : item.width,
					height: item.height ? Number(Number(item.height).toFixed(0)) : item.height,
					pieces: item.pieces ? Number(Number(item.pieces).toFixed(0)) : item.pieces,
				};
			});
		});

		return (
			<div
				style={{ border: '2px solid #E7E7E7' }}
				className='box-border p-[24px_36px] flex flex-col rounded-[8px] bg-white'
			>
				<div className='w-full flex justify-between'>
					<div className='w-[96px] h-[29px] p-[4.5px_9px] rounded-[37.5px] bg-primary-light-03 flex justify-center items-center body-4-r text-primary-dark-01'>
						{t(`Item`)} {index + 1}
					</div>

					<Form.Item name={['items', index, 'id']} noStyle>
						<Input className=' hidden' />
					</Form.Item>

					<Delete24pxIcon className=' cursor-pointer' onClick={() => handleDelete()} />
				</div>

				<Gap height='16px' />

				<div className='box-border w-full flex flex-col gap-[20px]'>
					<div className='flex items-center gap-[18px]'>
						{/* package type */}
						<div className='grid grid-cols-[min-content_auto] grid-rows-2 gap-x-[12px]'>
							<div></div>
							<FormItemTitle
								content={t('x Count', { x: t(item.package_type) })}
								required
								disabledGap
							/>
							<Form.Item
								name={['items', index, 'package_type']}
								rules={[{ required: true }]}
								className='w-[80px]'
							>
								<Select
									variant='borderless'
									options={packageingTypes.map((i) => ({ label: t(i), value: i }))}
									style={formItemWrapper.style}
									className={`${formItemWrapper.className}`}
								/>
							</Form.Item>

							<Form.Item
								name={['items', index, 'units']}
								rules={[{ validator: validatePositiveNumber }]}
								className='w-[90px]'
							>
								<InputNumber
									min={0}
									suffix={null}
									style={formItemWrapper.style}
									className={`${formItemWrapper.className} w-full`}
									onBlur={() => {
										turnIntoInt();
									}}
								/>
							</Form.Item>
						</div>

						{/* dimension_unit */}
						<div className='grid grid-cols-[min-content_auto_auto_auto_auto] grid-rows-2 gap-x-[8px]'>
							<div></div>

							{['Length', 'Width', 'Height'].map((i) => (
								<FormItemTitle content={t(i)} required disabledGap />
							))}

							<div></div>

							<FormItemTitle content={t('x Dimensions', { x: t(item.package_type) })} disabledGap />

							{['Length', 'Width', 'Height'].map((i) => (
								<Form.Item
									name={['items', index, i.toLowerCase()]}
									rules={[{ validator: validatePositiveNumber }]}
									className='w-[72px]'
								>
									<InputNumber
										min={0}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-full`}
										onBlur={() => {
											turnIntoInt();
										}}
									/>
								</Form.Item>
							))}

							<Form.Item
								name={['items', index, 'dimension_unit']}
								rules={[{ required: true }]}
								className='w-[72px]'
							>
								<Select
									variant='borderless'
									options={['in', 'cm'].map((i) => ({ label: i, value: i }))}
									style={formItemWrapper.style}
									className={`${formItemWrapper.className}`}
								/>
							</Form.Item>
						</div>

						{/* total weight */}
						<div className='grid grid-cols-[min-content_auto_70px] grid-rows-2 gap-x-[12px]'>
							<div></div>

							<FormItemTitle content={t(`Total weight`)} required disabledGap />

							<div></div>

							<FormItemTitle content={t('x Weight', { x: t(item.package_type) })} disabledGap />

							<Form.Item
								name={['items', index, 'total_weight']}
								rules={[{ validator: validatePositiveNumber }]}
								className='w-[100px]'
							>
								<InputNumber
									min={0}
									style={formItemWrapper.style}
									className={`${formItemWrapper.className} w-full`}
									onBlur={() => {
										turnIntoInt();
									}}
								/>
							</Form.Item>

							<Form.Item
								name={['items', index, 'weight_unit']}
								rules={[{ required: true }]}
								className='w-[70px]'
							>
								<Select
									variant='borderless'
									options={['lbs', 'kg'].map((i) => ({ label: i, value: i }))}
									style={formItemWrapper.style}
									className={`${formItemWrapper.className}`}
								/>
							</Form.Item>
						</div>

						<div className='w-[100px]'>
							<FormItemTitle content={t('Freight class')} required />
							<Form.Item name={['items', index, 'class']}>
								<Input
									disabled
									style={formItemWrapper.style}
									className={`${formItemWrapper.className}`}
								/>
							</Form.Item>
						</div>
					</div>

					<div className='flex items-center gap-[36px]'>
						<div className='w-[300px]'>
							<FormItemTitle content={t('Commodity Name')} required />
							<Form.Item
								name={['items', index, 'description']}
								rules={[{ required: true }, { validator: validateEnglishNumberSymbol }]}
								className='ant-form-item-error-nowrap'
							>
								<Input
									style={formItemWrapper.style}
									className={`${formItemWrapper.className}`}
									placeholder={t('Please describe your product information')}
								/>
							</Form.Item>
						</div>

						<div className='w-[300px]'>
							<FormItemTitle content={t('x Count', { x: t('Cartons') })} required />
							<Form.Item
								name={['items', index, 'pieces']}
								rules={[{ validator: validatePositiveNumber }]}
								className='w-full'
							>
								<InputNumber
									min={0}
									placeholder={t('Number of cartons or packages')}
									style={formItemWrapper.style}
									className={`${formItemWrapper.className} antd-number-input-w-full`}
									onBlur={() => {
										turnIntoInt();
									}}
								/>
							</Form.Item>
						</div>

						<div className='w-[200px]'>
							<FormItemTitle content={t('NMFC Code')} />
							<Form.Item name={['items', index, 'nmfc']}>
								<Input style={formItemWrapper.style} className={`${formItemWrapper.className}`} />
							</Form.Item>
						</div>
					</div>
				</div>
			</div>
		);
	},
);
