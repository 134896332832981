import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Pagination, Table, TableProps } from 'antd';
import { Doc, PrepayOrder } from '@/interfaces/prepay-order.interface';
import { getDocUrl } from '@/services/apis/get-doc-url';
import '@/styles/no-cell-border-table.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useMemoizedFn, useMount, useSize } from 'ahooks';
import { observer } from 'mobx-react-lite';
import AddFundsButton from './components/add-funds-button';
import NumberCards from './components/number-cards';
import { Gap } from '@/components/gap';
import usePrepayOrderParams from './hooks/use-prepay-order-params';
import { apiHooks } from '@/hooks/apis';

const handlePreview = async (file: Doc, record: PrepayOrder) => {
	const resp = await getDocUrl(file.id.toString(), 'prepay_documents', record.id);
	const url = resp.data;
	window.open(url, '_blank');
};

const PrepayOrderPage = observer(() => {
	const { t } = useTranslation();

	const { parsedSearchParams, changeSearchParams } = usePrepayOrderParams();

	const cardsRef = useRef<HTMLDivElement>(null);
	const cardsRefSize = useSize(cardsRef);
	const tableScroll: TableProps['scroll'] = {
		scrollToFirstRowOnChange: true,
		x: 'max-content',
		y: `calc(100vh - ${303 + (cardsRefSize?.height ?? 0)}px)`,
	};

	const {
		data: prepayOrders,
		loading: prepayOrdersLoading,
		pagination,
		refresh: refreshPrepayOrders,
	} = apiHooks.useGetPrepayOrders();

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const columns: TableProps<PrepayOrder>['columns'] = [
		{
			title: t('Order Number'),
			dataIndex: 'order_number',
			width: 160,
			fixed: 'left',
		},
		{
			title: t('Prepaid Amount'),
			dataIndex: 'prepaid_amount',
			width: 160,
		},
		{
			title: t('Prepaid Currency'),
			dataIndex: 'prepaid_currency',
			width: 160,
		},
		{
			title: t('Exchange Rate'),
			dataIndex: 'exchange_rate',
			width: 160,
		},
		{
			title: t('Exchange Rate Cost'),
			dataIndex: 'exchange_rate_cost',
			width: 180,
		},
		{
			title: t('Amount'),
			dataIndex: 'amount',
			width: 140,
			render: (v) => new Intl.NumberFormat('en-US').format(v),
		},
		{
			title: t('Bank Name'),
			dataIndex: 'bank_name',
			width: 160,
		},
		{
			title: t('Status'),
			dataIndex: 'status_name',
			width: 160,
			render: (v) => t(v),
		},
		{
			title: t('Doc of receipt'),
			dataIndex: 'docs',
			width: 160,
			render: (value, record) => (
				<>
					{record.documents.map((d: Doc) => (
						<Button
							type='link'
							className='px-0'
							key={d.id}
							onClick={() => handlePreview(d, record)}
						>
							{d.file_name}
						</Button>
					))}
				</>
			),
		},
		{
			title: t('Order Date'),
			dataIndex: 'created_at',
			width: 200,
		},
	];

	const handleChangePagination = useMemoizedFn((page: number, pageSize: number) => {
		changePagination(page, pageSize);
		changeSearchParams({ page, per_page: pageSize });
	});

	useMount(async () => {
		changePagination(parsedSearchParams.page ?? 1, parsedSearchParams.per_page ?? 20);
	});

	return (
		<div className='h-screen w-full p-[48px] flex flex-col bg-white overflow-hidden'>
			{/* header */}
			<div className='grow-0 shrink-0 w-full h-[44px] flex items-center gap-[8px]'>
				<div className='h3-b text-black'>{t('Wallet')}</div>
				{prepayOrdersLoading && <LoadingOutlined />}
				<div className='flex-auto' />
				<AddFundsButton refreshPrepayOrders={refreshPrepayOrders} />
			</div>

			<Gap height='36px' />

			{/* cards */}
			<NumberCards ref={cardsRef} />

			<Gap height='24px' />

			{/* table */}
			<div className='flex-auto'>
				<Table
					className='no-cell-border-table'
					rowKey='id'
					columns={columns}
					dataSource={prepayOrders}
					bordered
					pagination={false}
					scroll={tableScroll}
				></Table>
			</div>

			<Gap height='16px' />

			{/* pagination */}
			<Pagination
				className='mx-auto'
				showSizeChanger
				total={total}
				current={current}
				pageSize={pageSize}
				onChange={handleChangePagination}
			/>
		</div>
	);
});

export default PrepayOrderPage;
