import valueTransfer from '@/utils/value-transfer';
import { useMemoizedFn } from 'ahooks';
import qs from 'qs';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface AddressesQuery {
	keyword?: string;
	per_page?: number;
	page?: number;
	sort_by?: string;
	sort_value?: 'desc' | 'asc';
}

export default () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const parsedSearchParams = useMemo(() => {
		const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

		const res = {};

		valueTransfer(res, 'per_page', searchParams.per_page, (v) => (v ? Number(v) : 20));
		valueTransfer(res, 'page', searchParams.page, (v) => (v ? Number(v) : 1));
		valueTransfer(res, 'sort_by', searchParams.sort_by, (v) => String(v ?? ''));
		valueTransfer(res, 'sort_value', searchParams.sort_value, (v) => String(v ?? 'desc'));
		valueTransfer(res, 'keyword', searchParams.keyword, (v) => String(v ?? ''));

		return res as Partial<AddressesQuery>;
	}, [searchParams]);

	const changeSearchParams = useMemoizedFn((values: any) => {
		setSearchParams(qs.stringify({ ...parsedSearchParams, ...values }));
	});

	return {
		parsedSearchParams,
		changeSearchParams,
	};
};
